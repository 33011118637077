export enum Languages {
  en = 'en-us',
  ru = 'ru',
  es = 'es'
}

export interface IUser {
  id: number;
  name: string;
  real_name: string;
  comment: string;
  groups: number[];
  primary_group: number;
  active: boolean;
  password?: string;
  confirm_password?: string;
  passwordsValid?: boolean;
  language: Languages | '';
}
