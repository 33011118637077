<template>
  <page-layout :show-back-button="false">
    <span slot="header-name">{{ $tf('appearance') }}</span>
    <el-form slot="content" :size="$vars.sizes.form" :label-position="$vars.forms.label_position" :labelWidth="$vars.forms.label_width" :name="$options.name">
      <el-form-item :label="$tf('language')">
        <el-select v-model="$i18n.locale" size="mini" @change="localeHandler">
          <el-option :key="item.name" :value="item.name" :label="item.label" v-for="item in $store.getters.languages"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
  </page-layout>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';
import PageLayout from '@/components/page/layout';
import { Languages } from '../../store/users/users/interfaces';

@Component({
  name: 'page-appearance',
  components: { PageLayout }
})
export default class PageAppearance extends Vue {
  get state() {
    return this.$store.state.users;
  }
  get id() {
    return this.state.current.id;
  }
  localeHandler(v) {
    window.localStorage.locale = v;
    this.$store.state.app.acceptLanguage = this.$t('accept_language');
    this.changeLanguage(v);
  }
  changeLanguage(v) {
    const language = Languages[v] ?? Languages.en;
    this.$store.dispatch(this.state.Action.Update, { id: this.id, language });
  }
}
</script>
